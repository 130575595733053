import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toggleTheToggle } from "src/services/compareSerp/compareSerpActions";

import Layout from "src/layouts/LayoutWhiteHeader";
import PostListingDetailed from "src/components/generic/PostListingDetailed/PostListingDetailed";
import config from "data/SiteConfig";
import { userJourneyMapping } from "src/routesConstants";

import styles from "./learn.module.scss";

// eslint-disable-next-line react/prefer-stateless-function
class LearnPage extends React.Component {
	// onClickButton = () => {
	// 	console.log(this.props.actions);
	// 	this.props.actions.toggleTheToggle();
	// };

	render() {
		return (
			<Layout>
				<main>
					<Helmet title={`About | ${config.siteTitle}`} />
					<div className="greyBar p-t-lg p-b-lg">
						<div className="container">
							<h2 className="is-size-3">
								What do you want to learn?
							</h2>
							<ul>
								<li>
									<strong>New: </strong>Do you want the
									newest, hottest takes? This is them!!!!
									...... Articles ordered by date.
								</li>
								<li>
									<strong>Learn SEO: </strong>A collection of
									articles to help you learn SEO.
								</li>
								<li>
									<strong>Working with data: </strong>For most
									marketers, you&#39;ll need to work with data
									every day. Let&#39;s learn how to do it
									well.
								</li>
							</ul>
						</div>
					</div>
					<div className={`m-t-xl  m-b-xl container floatContainer`}>
						<span className="primaryBox largeRed"></span>
						<h2 className="is-size-3">New</h2>
						<PostListingDetailed
							postEdges={this.props.data.recent.edges}
							className={styles.contentWidth}
							twoColumns={true}
						/>
						<Link className="readMoreBox" to={"/all-posts/"}>
							Read All
						</Link>
					</div>
				</main>
			</Layout>
		);
	}
}

// {
/* <li>
<strong>Learn how the web works: </strong>If
you work in digital marketing, you&#39;re
going to need to know how websites work.
</li> */
// }

const mapDispatchToProps = dispatch => {
	return {
		actions: bindActionCreators({ toggleTheToggle }, dispatch)
	};
};

export default connect(
	null,
	mapDispatchToProps
)(LearnPage);

// The graphql query made here will automatically be entered as the
// prop {data}
export const pageQuery = graphql`
	query LearnQuery {
		# This is all the nodes ordered by date
		recent: allMdx(
			limit: 4
			sort: { fields: [fields___date], order: DESC }
			filter: { frontmatter: { standalone: { ne: true } } }
		) {
			edges {
				node {
					fields {
						slug
						date(formatString: "MMMM DD, YYYY")
					}
					frontmatter {
						h1title
						categories
						description
					}
				}
			}
		}
	}
`;

// export default about;
//
