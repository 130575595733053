export const returnEmptyIfNull = value => {
	return value ? value : "";
};

/*
    Array-aware equality checker:
    Returns whether arguments a and b are == to each other;
    however if they are equal-lengthed arrays, returns whether their 
    elements are pairwise == to each other recursively under this
    definition.
 */
export function arraysEqual(a, b) {
	if (a instanceof Array && b instanceof Array) {
		if (a.length !== b.length)
			// assert same length
			return false;
		for (
			let i = 0;
			i < a.length;
			i += 1 // assert each element equal
		)
			if (!arraysEqual(a[i], b[i])) return false;
		return true;
	}

	return a === b; // if not both arrays, should be the same
}

/**
 * This update function allows you to immutably update any value in a JSON
 * object. Primarily will be used with state.
 * @param  {list} path        This is a list of values which would move through JSON. i.e. [0, "name"]
 * @param  {value} anything   This will be what the value is updated with
 * @return {[boolean]}     Returns the state, but with the update.
 */
export const update = (path, value, state) => {
	if (path.length > 0) {
		const [key, ...rest] = path;
		if (typeof key === "number") {
			state = updateAtN(state, key, v => {
				return update(rest, value, v);
			});
		} else {
			state = updateKey(state, key, v => {
				return update(rest, value, v);
			});
		}
		return state;
	}
	return value;
};

/*
 * This calls a function on a value in a list at position n.
 */
const updateAtN = (list, n, f) => {
	let listCopy = [...list];
	listCopy[n] = f(listCopy[n]);
	return listCopy;
};

/* This calls a function for a key in an object. */
const updateKey = (obj, key, f) => {
	let objCopy = { ...obj };
	objCopy[key] = f(objCopy[key]);
	return objCopy;
};

/**
 * Runs multiple updates on the same object.
 * @param {*} pathValueList
 * @param {*} state
 * @returns
 */
export const multipleUpdates = (pathValueList, state) => {
	let output = state;

	console.log(pathValueList);
	console.log(output);

	pathValueList.forEach(pathValue => {
		const { path, value } = pathValue;

		output = update(path, value, output);
	});

	return output;
};

export function safeLowerString(str) {
	const removeUnsafe = str
		.replace(/[^a-zA-Z0-9_-]/g, "_")
		.replace(/_+/g, "_");

	const removeTrailingLeadingScore = removeUnsafe
		.trim()
		.toLowerCase()
		.replace(/^_|_$/g, "");
	return removeTrailingLeadingScore;
}

/**
 * This function takes a data structure and looks to see if a path
 * exists inside it and then returns the value of the path.
 *
 * Sauce: https://stackoverflow.com/questions/24924464/how-to-check-if-object-structure-exists
 * @param {} obj
 * @param {*} attrs
 */
export function usePathSelectIfExists(dataStructure, path) {
	let tmp = dataStructure;

	for (let i = 0; i < path.length; i += 1) {
		if (tmp[path[i]] === undefined) return false;
		tmp = tmp[path[i]];
	}

	return tmp;
}

/**
 * Checks if the value is null or undefined.
 * @param {*} variable - Any variable
 */
export function isNullNotZero(variable) {
	if (variable === null || variable === undefined) {
		return true;
	}

	return false;
}

/**
 * Checks if the value is not null or undefined.
 * @param {*} variable - Any variable
 */
export function isNotNull(variable) {
	return !isNullNotZero(variable);
}

/**
 * A horrendous chatgpt function for getting a cookie value...
 * @param {*} name
 * @returns
 */
export const getCookieValue = name => {
	const cookies = document.cookie.split(";");
	for (let i = 0; i < cookies.length; i++) {
		const parts = cookies[i].split("=");
		const cookieName = parts[0].trim();
		console.log(cookieName);
		if (cookieName === name) {
			return decodeURIComponent(parts.slice(1).join("="));
		}
	}
	return null;
};
